import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Guides`}</h1>
    <p>{`Guidance on how to make design choices that prioritize user experience.`}</p>
    <p>{`Get answers on topics like layout, messaging, and placement. We’ll be adding new guides regularly.`}</p>
    <h2>{`Available Guidance`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/using-spark/guides/alt-text"
        }}>{`Alternative Text`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/using-spark/guides/content-style"
        }}>{`Content Style`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/using-spark/guides/validation-messaging"
        }}>{`Validation Messaging`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/using-spark/guides/writing-meaningful-web-content-with-semantic-html"
        }}>{`Writing Meaningful Web Content With Semantic HTML`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      